import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { HomeHero } from '../components/hero';
import styled from 'styled-components';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import breakpoints from '../components/utils/breakpoints';

const SchedulePage = () => {
  return (
    <Layout>
      <Seo title="Shield Systems Academy Schedule - Martial Arts in Knoxville, Tennessee" />
      <HomeHero />
      <Wrapper>
        <h1>Schedule</h1>
        <LargeTable>
          <Thead>
            <Tr>
              <Th>Monday</Th>
              <Th>Tuesday</Th>
              <Th>Wednesday</Th>
              <Th>Thursday</Th>
              <Th>Saturday</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td>
                Adult BJJ Gi
                <br /> 6:30am - 8:00am
              </Td>
              <Td>&nbsp;</Td>
              <Td>
                Adult BJJ Gi
                <br /> 6:30am - 8:00am
              </Td>
              <Td>&nbsp;</Td>
              <Td>&nbsp;</Td>
            </Tr>
            <Tr>
              <Td>&nbsp;</Td>
              <Td>
                Adult BJJ Gi
                <br /> 11:30am - 1:00pm
              </Td>
              <Td>&nbsp;</Td>
              <Td>
                Adult BJJ Gi
                <br /> 11:30am - 1:00pm
              </Td>
              <Td>
                Open Mat
                <br /> 10:00am - 12:00pm
              </Td>
            </Tr>
            <Tr>
              <Td>
                Kids Kickboxing
                <br /> 4:00pm - 5:00pm
              </Td>
              <Td>
                Kids BJJ
                <br /> 4:00pm - 5:00pm
              </Td>
              <Td>
                Kids Kickboxing
                <br /> 4:00pm - 5:00pm
              </Td>
              <Td>
                Kids BJJ
                <br /> 4:00pm - 5:00pm
              </Td>
            </Tr>
            <Tr>
              <Td>
                Kids BJJ
                <br /> 5:00pm - 6:00pm
              </Td>
              <Td>
                Kids Kickboxing
                <br /> 5:00pm - 6:00pm
              </Td>
              <Td>
                Kids BJJ
                <br /> 5:00pm - 6:00pm
              </Td>
              <Td>
                Kids Kickboxing
                <br /> 5:00pm - 6:00pm
              </Td>
            </Tr>
            <Tr>
              <Td>
                Adult BJJ Gi
                <br /> 6:00pm - 7:30pm
              </Td>
              <Td>
                BJJ Sparring
                <br /> 6:00pm - 7:00pm
              </Td>
              <Td>
                Adult BJJ Gi
                <br /> 6:00pm - 7:30pm
              </Td>
              <Td>
                BJJ Sparring
                <br /> 6:00pm - 7:00pm
              </Td>
            </Tr>
            <Tr>
              <Td>
                Fighters Only
                <br /> 7:30pm - 9:00pm
              </Td>
              <Td>
                Adult Kickboxing
                <br /> 7:00pm - 8:00pm
              </Td>
              <Td>
                Fighters Only
                <br /> 7:30pm - 9:00pm
              </Td>
              <Td>
                Adult Kickboxing
                <br /> 7:00pm - 8:00pm
              </Td>
            </Tr>
            <Tr>
              <Td>&nbsp;</Td>
              <Td>
                Adult Kickboxing Sparring
                <br /> 8:00pm - 9:00pm
              </Td>
              <Td>&nbsp;</Td>
              <Td>
                Adult Kickboxing Sparring
                <br /> 8:00pm - 9:00pm
              </Td>
            </Tr>
          </Tbody>
        </LargeTable>
        <MobileSchedule>
          <h2>Monday</h2>
          <ul>
            <li>
              Adult BJJ Gi
              <br /> 6:30am - 8:00am
            </li>
            <li>
              Kids Kickboxing
              <br /> 4:00pm - 5:00pm
            </li>
            <li>
              Kids BJJ
              <br /> 5:00pm - 6:00pm
            </li>
            <li>
              Adult BJJ Gi
              <br /> 6:00pm - 7:30pm
            </li>
            <li>
              Fighters Only
              <br /> 7:30pm - 9:00pm
            </li>
          </ul>
          <h2>Tuesday</h2>
          <ul>
            <li>
              Adult BJJ Gi
              <br /> 11:30am - 1:00pm
            </li>
            <li>
              Kids BJJ
              <br /> 4:00pm - 5:00pm
            </li>
            <li>
              Kids Kickboxing
              <br /> 5:00pm - 6:00pm
            </li>
            <li>
              BJJ Sparring
              <br /> 6:00pm - 7:00pm
            </li>
            <li>
              Adult Kickboxing
              <br /> 7:00pm - 8:00pm
            </li>
            <li>
              Adult Kickboxing Sparring
              <br /> 8:00pm - 9:00pm
            </li>
          </ul>
          <h2>Wednesday</h2>
          <ul>
            <li>
              Adult BJJ Gi
              <br /> 6:30am - 8:00am
            </li>
            <li>
              Kids Kickboxing
              <br /> 4:00pm - 5:00pm
            </li>
            <li>
              Kids BJJ
              <br /> 5:00pm - 6:00pm
            </li>
            <li>
              Adult BJJ Gi
              <br /> 6:00pm - 7:30pm
            </li>
            <li>
              Fighters Only
              <br /> 7:30pm - 9:00pm
            </li>
          </ul>
          <h2>Thursday</h2>
          <ul>
            <li>
              Adult BJJ Gi
              <br /> 11:30am - 1:00pm
            </li>
            <li>
              Kids BJJ
              <br /> 4:00pm - 5:00pm
            </li>
            <li>
              Kids Kickboxing
              <br /> 5:00pm - 6:00pm
            </li>
            <li>
              BJJ Sparring
              <br /> 6:00pm - 7:00pm
            </li>
            <li>
              Adult Kickboxing
              <br /> 7:00pm - 8:00pm
            </li>
            <li>
              Adult Kickboxing Sparring
              <br />
              8:00pm - 9:00pm
            </li>
          </ul>
          <h2>Saturday</h2>
          <ul>
            <li>
              Open Mat
              <br /> 10:00am - 12:00pm
            </li>
          </ul>
        </MobileSchedule>
      </Wrapper>
    </Layout>
  );
};

const Wrapper = styled.div`
  margin: 2.38rem auto 0 auto;
  max-width: 960px;
  padding: 0 1.0875rem;
`;

const LargeTable = styled(Table)`
  @media (max-width: ${breakpoints.sm}) {
    display: none;
  }
`;

const MobileSchedule = styled.div`
  ul {
    list-style: none;

    li {
      padding: 10px;

      &:nth-child(odd) {
        background: #ddd;
      }
    }
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`;

export default SchedulePage;
